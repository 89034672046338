import { Component } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-link-modal',
  templateUrl: './link-modal.component.html',
})
export class LinkModalComponent {
  link: string;
  modal = {
    isOpen: false,
    text: '',
  };

  constructor(private linkService: LinkService) {
    this.linkService.showModal.subscribe((active) => {
      this.modal.isOpen = active;
    });
    this.linkService.modalLink.subscribe((text) => {
      this.link = text;
      this.modal.text =
        'Je gaat nu naar ' +
        text
          .replace(/\.de.*$/, '.de')
          .replace(/\.info.*$/, '.info')
          .replace(/\.com.*$/, '.com')
          .replace(/\.nl.*$/, '.nl')
          .replace(/https?:\/\//, '') +
        '. Deze website en de inhoud ervan valt niet onder verantwoordelijkheid van Teva Nederland BV.';
    });
  }

  gotoLink() {
    window.open(this.link, '_blank');
    this.modal.isOpen = false;
  }

  toggle(isOpen: boolean) {
    this.modal.isOpen = isOpen;
  }
}
