import { Medication } from './medication.model';
import { Doctor } from './doctor.model';
import { Registration } from './registration.model';

export class User {
  id: number;
  gender: string;
  name: string;
  infix: string;
  last_name: string;
  created_at: Date;
  updated_at: Date;
  roles: string[];
  isAjovy?: boolean;
  newsletter?: boolean;
  registrations: Registration[];
  medications: Medication[];
  doctors: Doctor[];

  get fullName(): string {
    return this.infix
      ? [this.name, this.infix, this.last_name].join(' ')
      : [this.name, this.last_name].join(' ');
  }

  get isAdmin(): boolean {
    if (!this.roles) {
      return false;
    }
    return this.roles.some(
      (role) =>
        role === 'super admin' ||
        role === 'content manager' ||
        role === 'helpdesk manager'
    );
  }

  constructor(user?: Partial<User>) {
    Object.assign(this, user);
  }
}
