import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MedicationService } from 'src/app/services/medication.service';
import FormHelper from '../../shared/helpers/form.helper';
import { HttpClient } from '@angular/common/http';
import { URL_CSRF_TOKEN } from 'src/app/shared/constants';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-validate-eu-number',
  templateUrl: './validate-eu-number.component.html',
})
export class ValidateEuNumberComponent implements OnInit {
  euNumberform = new UntypedFormGroup({
    number: new UntypedFormControl('', [Validators.required]),
  });
  loading: boolean;
  submitted: boolean = false;

  returnUrl: string;

  constructor(
    public medicationService: MedicationService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/ajovy';
  }

  submitEuCode() {
    this.submitted = true;
    if (this.euNumberform.valid) {
      this.loading = true;
      this.http.get(URL_CSRF_TOKEN)
        .pipe(mergeMap(() => this.medicationService.checkEuNumber(this.euNumberform.value.number)))
        .subscribe(({ success, registration_number }) => {
          if (success) {
            this.authService.setEuNumber(registration_number);
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.euNumberform.controls['number'].setErrors({ incorrect: true });
          }
          this.loading = false;
        });
    }
  }
  get euNumber() {
    return this.euNumberform.get('number');
  }

  hasErrors(name: string, type = null) {
    return FormHelper.hasErrors(this.euNumberform.controls[name], type);
  }

  home() {
    this.authService.home();
  }
}
