<div class="lg:pl-64 fixed right-0 z-50 w-full overflow-y-auto">
  <div
    class="border m-1 rounded flex items-center {{ cssClass(alert) }}"
    role="alert"
    *ngFor="let alert of alerts"
  >
    <span class="block px-3 sm:inline" [innerHTML]="alert.message"></span>
    <button
      type="button"
      role="button"
      class="px-4 py-3 ml-auto"
      (click)="removeAlert(alert)"
    >
      <svg
        class="fill-current h-6 w-6 {{ cssClass(alert) }}"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>Close</title>
        <path
          d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
        />
      </svg>
    </button>
  </div>
</div>
