<div class="flex min-h-screen">
    <main class="z-10 flex flex-col items-end w-full p-4 lg:w-1/2 lg:pl-10">
        <div class="z-10 w-full max-w-xl p-8 m-auto bg-white rounded-lg shadow-xl lg:pt-10 lg:m-0 lg:shadow-none">
            <a routerLink="/">
                <img src="./assets/images/logo.svg" alt="Care4migraine" />
            </a>
            <div class="pt-10 lg:pt-20">
                <h2 class="mb-2 text-2xl text-default">Welkom!</h2>
                <p class="max-w-xl mb-4 text-base text-black">Deze website biedt online ondersteuning voor mensen
                    met migraine die het medicijn AJOVY® (fremanezumab) gebruiken.</p>
                <p class="max-w-xl mb-10 text-base text-black">Gebruik je AJOVY? Nadat je bent ingelogd kan je
                    informatie en servicematerialen downloaden, bekijken en bestellen die je helpen bij het gebruik
                    van AJOVY.</p>
                <h2 class="mb-2 text-2xl text-default"> Inloggen met je EU nummer</h2>
                <p class="max-w-xl mb-10 text-base text-black">
                    Om toegang te krijgen tot de informatie over AJOVY moet je inloggen. Je kunt inloggen met
                    het EU- nummer (bijvoorbeeld: EU/1/22/3333/444) dat op de zijkant van de verpakking van je
                    geneesmiddel staat.</p>

                <form [formGroup]="euNumberform" (ngSubmit)="submitEuCode()" class="mb-10">
                    <span class="block text-xs font-bold text-gray-500 uppercase">
                        EU-nummer *
                    </span>
                    <div class="inline-flex">
                        <input type="text" class="border-l-4 border-teva-teal" formControlName="number" required
                            placeholder="EU/1/22/3333/444" />
                        <button type="submit" appButton class="w-1/2 ml-2" color="teva" [loading]="loading"
                            [disabled]="!euNumberform.valid">
                            Inloggen
                        </button>
                    </div>
                    <label class="w-full px-3 py-2 sm:py-3">
                        <div *ngIf="submitted && euNumber.errors?.['required']" class="text-red-500">
                            EU-nummer is verplicht
                        </div>
                        <div *ngIf="submitted && hasErrors('number', 'incorrect')" class="text-red-500">
                            EU-nummer is niet correct
                        </div>
                    </label>
                </form>
                <h2 class="mb-2 text-2xl text-default">Problemen met inloggen?</h2>
                <p class="max-w-sm text-base text-black">
                    Problemen met inloggen? Stuur een bericht naar <a
                        class="underline hover:no-underline focus:no-underline" routerLink="/contact">Teva
                        Customer Service</a>.</p>
            </div>
        </div>
    </main>
    <div class="fixed top-0 right-0 w-full h-screen bg-right-bottom bg-no-repeat bg-cover lg:bg-contain bg-user-login"
        role="img" aria-label="Care4migraine -inloggen"></div>
</div>