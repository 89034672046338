import { Injectable } from '@angular/core';
import { URL_MUSIC } from '../shared/constants';
import { Music } from '../shared/models/audio.model';
import { AudioService } from './audio.service';

@Injectable({
  providedIn: 'root',
})
export class MusicService extends AudioService<Music> {
  protected baseUrl: string = URL_MUSIC;

  protected createAudioInstance(data: Music) {
    return new Music(data);
  }
}
