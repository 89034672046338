import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_MIGRAINEPAGES } from '../shared/constants';
import toFormData from '../shared/helpers/form-data.helper';
import { Category } from '../shared/models/category.model';
import { MigrainePage } from '../shared/models/migraine-page.model';
import { Pagination, RawPagination } from '../shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class MigrainePageService {
  migrainePages: MigrainePage[];

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<MigrainePage>
  ): Observable<RawPagination<MigrainePage>> {
    return this.http.get<RawPagination<MigrainePage>>(URL_MIGRAINEPAGES, {
      params: { ...pagination?.params, ...options },
    });
  }

  reorder(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_MIGRAINEPAGES}/order/${id}`, {
      previous: data,
    });
  }

  reorderCategory(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_MIGRAINEPAGES}/category/order/${id}`, {
      previous: data,
    });
  }

  getCategories(
    options: { [key: string]: any } = {},
    pagination?: Pagination<Category>
  ): Observable<RawPagination<Category>> {
    return this.http.get<RawPagination<Category>>(
      `${URL_MIGRAINEPAGES}/categories`,
      { params: { ...pagination?.params, ...options } }
    );
  }

  getForCategory(
    id: number,
    pagination?: Pagination<MigrainePage>
  ): Observable<RawPagination<MigrainePage>> {
    return this.http.get<RawPagination<MigrainePage>>(
      `${URL_MIGRAINEPAGES}/category-pages/${id}`,
      { params: pagination?.params }
    );
  }

  get(id: number): Observable<MigrainePage> {
    return this.http
      .get<{ data: MigrainePage }>(`${URL_MIGRAINEPAGES}/${id}`)
      .pipe(map(({ data }) => new MigrainePage(data)));
  }

  getCategory(id: number): Observable<Category> {
    return this.http
      .get<{ data: Category }>(`${URL_MIGRAINEPAGES}/category/${id}`)
      .pipe(map(({ data }) => data));
  }

  create(data: MigrainePage): Observable<MigrainePage> {
    return this.http.post<MigrainePage>(
      `${URL_MIGRAINEPAGES}`,
      toFormData(data)
    );
  }

  createCategory(data): Observable<any> {
    return this.http.post(`${URL_MIGRAINEPAGES}/category`, toFormData(data));
  }

  update(id: number, data: MigrainePage): Observable<any> {
    return this.http.post(
      `${URL_MIGRAINEPAGES}/${id}`,
      toFormData(data, 'PUT')
    );
  }

  updateCategory(id, data): Observable<any> {
    return this.http.post(
      `${URL_MIGRAINEPAGES}/category/${id}`,
      toFormData(data, 'PUT')
    );
  }

  delete(id): Observable<any> {
    return this.http.delete(`${URL_MIGRAINEPAGES}/${id}`);
  }

  deleteCategory(id): Observable<any> {
    return this.http.delete(`${URL_MIGRAINEPAGES}/category/${id}`);
  }
}
