import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DosageForm, Medication } from '../shared/models/medication.model';
import {
  URL_MEDICATION,
  URL_MEDICATION_CHECK,
  URL_DOSAGEFORMS,
  URL_EU_NUMBER_CHECK,
} from '../shared/constants';
import { Pagination, RawPagination } from '../shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class MedicationService {
  medication: Medication[];

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<Medication>
  ): Observable<RawPagination<Medication>> {
    return this.http.get<RawPagination<Medication>>(URL_MEDICATION, {
      params: { ...pagination?.params, ...options },
    });
  }

  getDosageForms(): Observable<{ dosageForms: DosageForm[] }> {
    return this.http.get<any>(URL_DOSAGEFORMS);
  }

  addMedication(
    data: Partial<Medication>
  ): Observable<{ success: boolean; medication: Medication }> {
    return this.http.post<any>(URL_MEDICATION, data);
  }

  checkMedication(
    number: string,
    id: number
  ): Observable<{ success: boolean }> {
    return this.http.post<any>(URL_MEDICATION_CHECK, {
      registration_number: number,
      medication_id: id,
    });
  }

  checkEuNumber(
    number: string
  ): Observable<{ success: boolean; registration_number: string }> {
    return this.http.post<any>(URL_EU_NUMBER_CHECK, {
      registration_number: number,
    });
  }
}
