<app-sidebar></app-sidebar>
<div class="min-h-screen body">
  <app-breadcrumbs></app-breadcrumbs>

  <router-outlet></router-outlet>

  <div class="mx-auto mt-12 lg:container">
    <app-footer></app-footer>
  </div>
</div>

<app-link-modal></app-link-modal>