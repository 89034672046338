import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_CONTENTPAGES } from '../shared/constants';
import { ContentPage } from '../shared/models/content-page.model';
import { Pagination, RawPagination } from '../shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class ContentPageService {
  contentPages: ContentPage[];

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<ContentPage>
  ): Observable<RawPagination<ContentPage>> {
    return this.http.get<RawPagination<ContentPage>>(URL_CONTENTPAGES, {
      params: { ...pagination?.params, ...options },
    });
  }

  get(id: number): Observable<ContentPage> {
    return this.http
      .get<{ data: ContentPage }>(`${URL_CONTENTPAGES}/${id}`)
      .pipe(map(({ data }) => new ContentPage(data)));
  }

  create(data: ContentPage): Observable<ContentPage> {
    return this.http.post<ContentPage>(`${URL_CONTENTPAGES}`, data);
  }

  update(id: number, data: ContentPage): Observable<ContentPage> {
    return this.http.put<ContentPage>(`${URL_CONTENTPAGES}/${id}`, data);
  }

  delete(id: number): Observable<ContentPage> {
    return this.http.delete<ContentPage>(`${URL_CONTENTPAGES}/${id}`);
  }
}
