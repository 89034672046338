import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import toFormData from '../shared/helpers/form-data.helper';
import { Pagination, RawPagination } from '../shared/models/pagination.model';
import { Tag } from '../shared/models/tag.model';

@Injectable({
  providedIn: 'root',
})
export class AudioService<T> {
  protected baseUrl: string;

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<T>
  ): Observable<RawPagination<T>> {
    return this.http.get<RawPagination<T>>(this.baseUrl, {
      params: { ...pagination?.params, ...options },
    });
  }

  reorder(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${this.baseUrl}/order/${id}`, {
      previous: data,
    });
  }

  get(id: number | string): Observable<T> {
    return this.http
      .get<{ data: T }>(`${this.baseUrl}/${id}`)
      .pipe(map(({ data }) => this.createAudioInstance(data)));
  }

  create(data: T): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}`, toFormData(data));
  }

  update(id: number, data: T): Observable<any> {
    return this.http.post(`${this.baseUrl}/${id}`, toFormData(data, 'PUT'));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  getTags(
    options: { [key: string]: any } = {},
    pagination?: Pagination<Tag>
  ): Observable<RawPagination<Tag>> {
    return this.http.get<RawPagination<Tag>>(`${this.baseUrl}/tags`, {
      params: { ...pagination?.params, ...options },
    }).pipe(
      map(items => ({
        ...items,
        data: items.data.map((tag) => ({
          ...tag,
          defaultName: tag.name.nl,
          defaultSlug: tag.slug.nl
        }))
      }))
    );
  }

  getForTag(id: number, pagination?: Pagination<T>): Observable<T[]> {
    return this.http.get<any>(`${this.baseUrl}/tag-audio/${id}`, {
      params: pagination?.params,
    });
  }

  getTag(id: number | string): Observable<Tag> {
    return this.http
      .get<{ data: Tag }>(`${this.baseUrl}/tag/${id}`)
      .pipe(map(({ data }) => data));
  }

  createTag(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/tag`, data);
  }

  updateTag(id, data): Observable<any> {
    return this.http.put(`${this.baseUrl}/tag/${id}`, data);
  }

  deleteTag(id): Observable<any> {
    return this.http.delete(`${this.baseUrl}/tag/${id}`);
  }

  protected createAudioInstance(data: T): T {
    return null;
  }
}
