import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTags',
})
export class StripTagsPipe implements PipeTransform {
  static allowedTags =
    /<\s*(?!\/?(strong|i[^mg]|em|b|span|h\d|sup|sub|ul|ol|li))[^>]*>/gi;

  transform(value: string): string {
    return value
      .replace(StripTagsPipe.allowedTags, '')
      .replace(/[\s]{2,}/gi, ' ');
  }
}
