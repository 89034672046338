<div
  class="fixed top-0 left-0 z-50 flex items-start justify-center w-screen h-screen p-4 pb-16 overflow-y-auto bg-gray-500 bg-opacity-50 sm:pb-4 backdrop-blur-2"
  *ngIf="isOpen"
  #modalWrapper
>
  <div
    class="relative w-full max-w-lg m-auto border-2 card"
    [ngClass]="modalClass"
    #modal
  >
    <div class="flex items-center justify-end p-2" *ngIf="hasCloseButton">
      <button
        (click)="close()"
        aria-label="sluiten"
        title="Sluiten"
        class="px-5 py-2 font-bold text-right text-blue-900 bg-white rounded"
      >
        <svg aria-label="close" class="w-6 h-6 cursor-pointer">
          <use xlink:href="#fas-times-circle" />
        </svg>
      </button>
    </div>

    <div
      class="card__content"
      *ngIf="hasHeader"
      [ngClass]="{ 'pt-0': hasCloseButton }"
    >
      <ng-content select="[header]"></ng-content>
    </div>

    <section>
      <ng-content select="[body]"></ng-content>
    </section>

    <div class="pt-6 card__content" *ngIf="hasFooter">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
