import { RelatedPage } from './related-page';
import { JSONLDSchema } from './schema.model';

export class AboutPage extends JSONLDSchema<AboutPage> {
  id: number;
  title: string;
  slug: string;
  preview_text: string;
  text_mobile: string;
  text_desktop: string;
  category_id: number;
  created_at: Date;
  updated_at: Date;
  parent_id: number;
  parent: AboutPage;
  children: Array<AboutPage>;
  siblings: Array<AboutPage>;
  menu: boolean;
  thumbnail: string;
  banner: string;
  metadata?: string;
  alternate_link?: string;
  related?: RelatedPage[];
}
