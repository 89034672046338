import { BreadcrumbsService } from './breadcrumbs.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, } from '@angular/router';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { GTMService } from './gtm.service';
import { SafeUrlService } from './safe-url.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  end: NavigationEnd;
  canonicalLink: HTMLElement;
  alternateLinkNlBe: HTMLElement;
  alternateLinkNlNL: HTMLElement;

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private metaTagService: Meta,
    private gtmService: GTMService,
    private safeUrlService: SafeUrlService,
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(DOCUMENT) private document,
    @Optional() @Inject(REQUEST) private request: Request
  ) {
    this.alternateLinkNlBe = document.querySelector(
      'link[rel=alternate][hreflang=nl-be]'
    );
    this.alternateLinkNlNL = document.querySelector(
      'link[rel=alternate][hreflang=nl-nl]'
    );
    this.canonicalLink = document.querySelector('link[rel=canonical]');
    router.events
      .pipe(filter((nav) => nav instanceof NavigationStart))
      .subscribe(() => {
        this.removeNoIndex();
        this.unsetAlternate();
      });

    router.events
      .pipe(
        filter((nav) => nav instanceof NavigationEnd),
        tap((nav: NavigationEnd) => {
          this.end = nav;
        }),
        mergeMap(() => this.getChild(activatedRoute).data)
      )
      .subscribe((data: { title: string; metadata?: string; }) => {
        if (data.title) this.setTitle(data);
        if (data.metadata) this.setMetaDescription(data);
        this.setCanonical(this.safeUrlService.getFullUrl(this.end.url.split(/[?#]+/)[0]));
      });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    return activatedRoute.firstChild
      ? this.getChild(activatedRoute.firstChild)
      : activatedRoute;
  }

  getTitle() {
    this.titleService.getTitle();
  }

  /**
   *
   * @param input
   * Sets the title attribute and calls the pageview method
   */
  setTitle(
    input: { title: string; metadata?: string; },
    noIndex: boolean = false
  ) {
    this.titleService.setTitle(input.title);

    this.breadcrumbsService.fixTitle(input.title);

    if (input.metadata) {
      this.setMetaDescription(input);
    }
    this.addPageview(input);

    if (!noIndex) {
      this.removeNoIndex();
    } else {
      this.setNoIndex();
    }
  }

  removeNoIndex(): void {
    this.metaTagService.removeTag("name='robots'");
  }

  setNoIndex(): void {
    this.metaTagService.updateTag({
      name: 'robots',
      content: 'noindex',
    });
  }

  setMetaDescription(input: { metadata?: string; }) {
    this.metaTagService.updateTag({
      name: 'description',
      content: input.metadata,
    });
  }

  setAlternate(url?: string) {
    if (!url) return;
    this.alternateLinkNlBe = this.setLinkAttributes(
      this.alternateLinkNlBe,
      url,
      'alternate',
      'nl-be'
    );
    var nlUrl = '';
    if (isPlatformBrowser(this.platform)) {
      nlUrl = window.location.href;
    } else {
      nlUrl = this.safeUrlService.getFullUrl(this.request.url);
    }
    this.alternateLinkNlNL = this.setLinkAttributes(
      this.alternateLinkNlNL,
      nlUrl,
      'alternate',
      'nl-nl'
    );
  }

  setCanonical(url?: string) {
    if (!url) return;

    this.canonicalLink = this.setLinkAttributes(
      this.canonicalLink,
      url,
      'canonical'
    );
  }

  setLinkAttributes(
    element?: any,
    url?: string,
    type?: string,
    lang?: string
  ): HTMLElement {
    if (!element) {
      element = this.document.createElement('link');
      element.setAttribute('rel', type);
      this.document.head.appendChild(element);
    }
    if (lang) element.setAttribute('hreflang', lang);
    element.setAttribute('href', url);
    return element;
  }

  unsetAlternate() {
    if (this.alternateLinkNlBe) {
      this.alternateLinkNlBe.remove();
      this.alternateLinkNlBe = undefined;
    }
    if (this.alternateLinkNlNL) {
      this.alternateLinkNlNL.remove();
      this.alternateLinkNlNL = undefined;
    }
  }

  unsetCanonical() {
    if (this.canonicalLink) {
      this.canonicalLink.remove();
      this.canonicalLink = undefined;
    }
  }

  addPageview(input) {
    const gtmTag = {
      url: this.end.url,
      title: input.title,
    };
    this.gtmService.addPageview(gtmTag);
    if (isPlatformBrowser(this.platform)) {
      window.scrollTo(0, 0);
    }
  }
}
