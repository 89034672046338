import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() isOpen = false;
  @Input() hasFooter = true;
  @Input() hasHeader = true;
  @Input() hasCloseButton = true;
  /** @var clickOutside Close modal when clicked outside. */
  @Input() clickOutside = false;

  private _modalClass: string = 'border-dark-gray-200';

  @Input()
  set modalClass(modalClass: string) {
    this._modalClass = modalClass ?? 'border-dark-gray-200';
  }
  get modalClass(): string {
    return this._modalClass;
  }

  @Output() toggle = new EventEmitter<boolean>();

  @ViewChild('modalWrapper') modalWrapper: ElementRef;
  @ViewChild('modal') modal: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  click(event: PointerEvent) {
    if (
      this.clickOutside &&
      !this.modal?.nativeElement.contains(event.target) &&
      this.modalWrapper?.nativeElement.isEqualNode(event.target)
    ) {
      this.close();
    }
  }

  open() {
    this.toggle.emit(true);
  }

  close() {
    this.toggle.emit(false);
  }
}
