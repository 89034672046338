import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: 'picture > source ~ img, img',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src',
  },
})
export class ImgFallbackDirective {
  @Input() src: string;
  @Input() default: string = '/assets/images/header-home.jpg';

  constructor(public hostElement: ElementRef) {}

  updateUrl() {
    if (
      this.src &&
      this.hostElement.nativeElement.previousElementSibling?.srcset
    ) {
      this.hostElement.nativeElement.previousElementSibling.srcset = this.src;
    } else {
      this.src = this.default;
    }
  }
}
