import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { BlogPostService } from '../services/blog-post.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { PodcastService } from '../services/podcast.service';
import { MusicService } from '../services/music.service';
import { AboutPageService } from '../services/about-page.service';
import { EventService } from '../services/event.service';
import { InDeAanvalService } from '../services/in-de-aanval.service';
import { MigrainePageService } from '../services/migraine-page.service';
import { AjovyPageService } from '../services/ajovy-page.service';
import { ContentPageService } from '../services/content-page.service';

@Injectable({
  providedIn: 'root',
})
export class SlugResolver implements Resolve<boolean> {
  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    public blogPostService: BlogPostService,
    public podcastService: PodcastService,
    public musicService: MusicService,
    public aboutPageService: AboutPageService,
    public eventService: EventService,
    public inDeAanvalService: InDeAanvalService,
    public migrainePageService: MigrainePageService,
    public ajovyPageService: AjovyPageService,
    public contentPageService: ContentPageService,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {}

  private services = {
    BlogTagComponent: { service: this.blogPostService, method_name: 'getTag' },
    BlogCategoryComponent: {
      service: this.blogPostService,
      method_name: 'getCategory',
    },
    BlogDetailComponent: { service: this.blogPostService, method_name: 'get' },
    PodcastDetailComponent: {
      service: this.podcastService,
      method_name: 'get',
    },
    MusicDetailComponent: { service: this.musicService, method_name: 'get' },
    AboutPageDetailComponent: {
      service: this.aboutPageService,
      method_name: 'get',
    },
    EventDetailComponent: { service: this.eventService, method_name: 'get' },
    InDeAanvalDetailComponent: {
      service: this.inDeAanvalService,
      method_name: 'get',
    },
    MigrainePageCategoryComponent: {
      service: this.migrainePageService,
      method_name: 'getCategory',
    },
    MigrainePageDetailComponent: {
      service: this.migrainePageService,
      method_name: 'get',
    },
    AjovyPageDetailComponent: {
      service: this.ajovyPageService,
      method_name: 'get',
    },
    ContentPageDetailComponent: {
      service: this.contentPageService,
      method_name: 'get',
    },
  };

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const param = route.paramMap.get('id');

    if (!Number(param)) return of(true);

    const service = this.services[route.routeConfig.component.name];

    return service.service[service.method_name](param).pipe(
      map((data: any) => {
        const redirectSlug =
          typeof data.slug === 'string' ? data.slug : data.slug?.nl;
        return this.redirect(state, param, redirectSlug);
      })
    );
  }

  private async redirect(
    state: RouterStateSnapshot,
    oldSlug: string,
    redirectSlug?: string
  ) {
    if (isPlatformServer(this.platformId)) {
      this.response.location(state.url.replace(oldSlug, redirectSlug));
      this.response.status(301);
    } else {
      await this.router.navigateByUrl(state.url.replace(oldSlug, redirectSlug));
    }

    return false;
  }
}
