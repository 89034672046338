import { isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { from, Observable, of } from 'rxjs';
import { Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class BaseRedirectResolver implements Resolve<boolean> {
  constructor(
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private location: Location
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const original = state.url.split('/')[1];
    const replacement = route.data.redirect;

    return from(this.redirect(state, original, replacement));
  }

  private async redirect(
    state: RouterStateSnapshot,
    originalBase: string,
    redirectBase?: string
  ) {
    const redirectUrl = state.url.replace(originalBase, redirectBase);

    if (isPlatformServer(this.platformId)) {
      this.response.location(redirectUrl);
      this.response.status(301);
    } else {
      await this.router.navigateByUrl(redirectUrl, {
        replaceUrl: true,
      });
    }

    return false;
  }
}
