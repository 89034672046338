import { JSONLDSchema } from './schema.model';

export abstract class Audio extends JSONLDSchema<Audio> {
  id: number;
  title: string;
  slug: string;
  duration: number;
  author: string;
  preview_text: string;
  text_mobile: string;
  text_desktop: string;
  created_at: Date;
  updated_at: Date;
  type: string;
  thumbnail: string;
  banner: string;
  audio?: string;
  metadata?: string;
  alternate_link?: string;
}

export class Podcast extends Audio {
  type = 'podcast';
}

export class Music extends Audio {
  type = 'music';
}
