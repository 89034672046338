import { Speaker } from './../shared/models/speaker.model';
import { Statement } from './../shared/models/statement.model';
import {
  URL_IN_DE_AANVAL,
  URL_SPEAKER,
  URL_STATEMENT,
} from './../shared/constants';
import {
  InDeAanval,
  SubscriptionForm,
} from './../shared/models/in-de-aanval.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import toFormData from '../shared/helpers/form-data.helper';
import { Pagination, RawPagination } from '../shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class InDeAanvalService {
  data: InDeAanval[];

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<InDeAanval>
  ): Observable<RawPagination<InDeAanval>> {
    return this.http.get<RawPagination<InDeAanval>>(URL_IN_DE_AANVAL, {
      params: { ...pagination?.params, ...options },
    });
  }

  getAllSpeakers(
    options: { [key: string]: any } = {},
    pagination?: Pagination<Speaker>
  ): Observable<RawPagination<Speaker>> {
    return this.http.get<RawPagination<Speaker>>(URL_SPEAKER, {
      params: { ...pagination?.params, ...options },
    });
  }

  getAllStatements(
    options: { [key: string]: any } = {},
    pagination?: Pagination<Statement>
  ): Observable<RawPagination<Statement>> {
    return this.http.get<RawPagination<Statement>>(URL_STATEMENT, {
      params: { ...pagination?.params, ...options },
    });
  }

  reorder(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_IN_DE_AANVAL}/order/${id}`, {
      previous: data,
    });
  }

  reorderSpeaker(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_SPEAKER}/order/${id}`, {
      previous: data,
    });
  }

  reorderStatement(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_STATEMENT}/order/${id}`, {
      previous: data,
    });
  }

  get(id: number | string): Observable<InDeAanval> {
    return this.http
      .get<{ data: InDeAanval }>(`${URL_IN_DE_AANVAL}/${id}`)
      .pipe(map(({ data }) => new InDeAanval(data)));
  }

  getSpeaker(id: number): Observable<Speaker> {
    return this.http
      .get<{ data: Speaker }>(`${URL_SPEAKER}/${id}`)
      .pipe(map(({ data }) => data));
  }

  getStatement(id: number): Observable<Statement> {
    return this.http
      .get<{ data: Statement }>(`${URL_STATEMENT}/${id}`)
      .pipe(map(({ data }) => data));
  }

  create(data: InDeAanval): Observable<InDeAanval> {
    return this.http.post<InDeAanval>(`${URL_IN_DE_AANVAL}`, toFormData(data));
  }

  createSpeaker(data: Speaker): Observable<Speaker> {
    return this.http.post<Speaker>(`${URL_SPEAKER}`, toFormData(data));
  }

  createStatement(data: Statement): Observable<Statement> {
    return this.http.post<Statement>(`${URL_STATEMENT}`, toFormData(data));
  }

  update(id: number, data: InDeAanval): Observable<any> {
    return this.http.post(`${URL_IN_DE_AANVAL}/${id}`, toFormData(data, 'PUT'));
  }

  updateSpeaker(id: number, data: Speaker): Observable<any> {
    return this.http.post(`${URL_SPEAKER}/${id}`, toFormData(data, 'PUT'));
  }

  updateStatement(id: number, data: Statement): Observable<any> {
    return this.http.post(`${URL_STATEMENT}/${id}`, toFormData(data, 'PUT'));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${URL_IN_DE_AANVAL}/${id}`);
  }

  deleteSpeaker(id): Observable<any> {
    return this.http.delete(`${URL_SPEAKER}/${id}`);
  }

  deleteStatement(id): Observable<any> {
    return this.http.delete(`${URL_STATEMENT}/${id}`);
  }

  sendQuestion(data: SubscriptionForm): Observable<any> {
    return this.http.post<SubscriptionForm>(
      `${URL_IN_DE_AANVAL}/subscribe`,
      data
    );
  }

  sendStatement(id: number, type: string): Observable<any> {
    return this.http.post<any>(`${URL_STATEMENT}/answer/${id}`, {
      type,
    });
  }
}
