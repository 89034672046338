<app-modal
  [isOpen]="modal.isOpen"
  (toggle)="toggle($event)"
  class="fixed top-0 left-0 z-40 w-screen h-screen"
  *ngIf="modal.isOpen"
>
  <div class="text-center" header>
    <h3 class="mb-3 -mt-4 text-3xl">Let op!</h3>
    <p [innerHTML]="modal.text"></p>
  </div>

  <div
    footer
    class="flex flex-col items-center justify-center sm:flex-row sm:space-x-6"
  >
    <button
      (click)="this.modal.isOpen = false"
      class="w-full px-5 py-2 font-bold text-blue-900 bg-white rounded"
    >
      Nee
    </button>
    <button
      (click)="gotoLink()"
      class="w-full px-5 py-2 font-bold text-white rounded bg-teva-teal"
    >
      Ja
    </button>
  </div>
</app-modal>
