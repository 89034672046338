import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})

export class FooterComponent {
  year: number = new Date().getFullYear();
  hasEuNumber$: Observable<boolean>;

  constructor(authService: AuthService) {
    this.hasEuNumber$ = authService.hasEuNumber;
  }
}
