import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { LinkModalComponent } from 'src/app/shared/components/link-modal/link-modal.component';

@NgModule({
  declarations: [ModalComponent, LinkModalComponent],
  imports: [CommonModule],
  exports: [ModalComponent, LinkModalComponent],
})
export class ModalModule {}
