<div
  class="flex flex-wrap items-center px-3 py-3 mx-auto space-x-2 text-sm sm:px-8 lg:container text-sea-blue-700"
>
  <!-- This element prevents layout shift when loading the breadcrumbs. -->
  &shy;

  <ng-container *ngIf="(breadcrumbs$ | async).length">
    <ngx-json-ld [json]="structuredData$ | async"></ngx-json-ld>

    <a routerLink="/">Home</a>
    <ng-container *ngFor="let crumb of breadcrumbs$ | async; let last = last">
      <svg height="10" width="8" class="text-xs fill-current text-sea-blue-700">
        <use xlink:href="#separator-icon" />
      </svg>
      <ng-container *ngIf="!last; else lastItem">
        <a [routerLink]="crumb.url">
          {{ crumb.text }}
        </a>
      </ng-container>
      <ng-template #lastItem>
        <span class="text-default">
          {{ crumb.text }}
        </span>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
