import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {
  Breadcrumb,
  BreadcrumbsService,
} from 'src/app/services/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]>;
  structuredData$: Observable<any>;

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbsService.breadcrumbs;
    this.structuredData$ = this.breadcrumbsService.structuredData;

    this.breadcrumbsService.setBreadcrumbs(this.route.snapshot);
  }
}
