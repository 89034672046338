import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  constructor(
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platform: Object,
    private authService: AuthService
  ) { }

  /**
   *
   * @param param {title, url}
   */
  addPageview({ title, url }: { title: string; url: string; }) {
    this.sendEvent({
      event: 'page',
      options: {
        pageName: url,
        title,
      },
    });
  }

  /**
   *
   * @param param0
   * Custom event for GTM tag
   */
  sendEvent({
    event,
    options,
  }: {
    event: string;
    options?: { [key: string]: string; };
  }) {
    if (isPlatformBrowser(this.platform)) {
      const gtmEvent = {
        event,
        logged_in: this.authService.analyticsStatus(),
        ...options,
      };

      if (environment.production) {
        this.gtmService.pushTag(gtmEvent);
      } else {
        console.debug(gtmEvent);
      }
    }
  }
}
