import { JSONLDSchema } from './schema.model';

export class InDeAanval extends JSONLDSchema<InDeAanval> {
  id: number;
  title: string;
  slug: string;
  sub_title: string;
  preview_text: string;
  text_desktop: string;
  created_at: Date;
  updated_at: Date;
  thumbnail: string;
  banner: string;
  alternate_link?: string;
}

export class SubscriptionForm {
  gender: string;
  first_name: string;
  email: string;
  optin_newsletter: boolean;
}
