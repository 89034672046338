import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private modalSubject: BehaviorSubject<boolean>;
  public showModal: Observable<boolean>;
  public modalLink: Subject<string> = new Subject<string>();

  constructor() {
    this.modalSubject = new BehaviorSubject(false);
    this.showModal = this.modalSubject.asObservable();
  }

  /**
   *
   * @param link
   * Evaluate given action (mouse event)
   * Detect is it's a link, if so if it's internal/external
   */
  evaluateLink(event) {
    const link = event.target.href || event.currentTarget.href;
    if (!link) {
      return;
    }

    event.preventDefault();
    if (
      (!link.startsWith('http://') && !link.startsWith('https://')) ||
      link.match(/^(https?:\/\/)?(www\.)?care4migraine\.nl/gi) ||
      link.match(/^(https?:\/\/)?care4migraine\-web\.everywhereim\.com/gi)
    ) {
      // Determine type of link (blank or self)
      // Internal link
      window.open(link, event.target.target ? event.target.target : '_self');
    } else {
      // External link
      this.modalSubject.next(true);
      this.modalLink.next(link);
    }
  }
}
