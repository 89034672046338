import { JSONLDSchema } from './schema.model';

export class ContentPage extends JSONLDSchema<ContentPage> {
  id: number;
  title: string;
  slug: string;
  preview_text: string;
  text_mobile: string;
  text_desktop: string;
  created_at: Date;
  updated_at: Date;
  metadata?: string;
  alternate_link?: string;
}
