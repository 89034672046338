import { Injectable } from '@angular/core';
import { URL_PODCASTS } from '../shared/constants';
import { Podcast } from '../shared/models/audio.model';
import { AudioService } from './audio.service';

@Injectable({
  providedIn: 'root',
})
export class PodcastService extends AudioService<Podcast> {
  protected baseUrl: string = URL_PODCASTS;

  protected createAudioInstance(data: Podcast) {
    return new Podcast(data);
  }
}
