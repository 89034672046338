import { Category } from './category.model';
import { RelatedPage } from './related-page';
import { JSONLDSchema } from './schema.model';

export class MigrainePage extends JSONLDSchema<MigrainePage> {
  id: number;
  title: string;
  slug: string;
  date: string;
  preview_text: string;
  text_mobile: string;
  text_desktop: string;
  category_id: number;
  created_at: Date;
  updated_at: Date;
  category: Category;
  thumbnail: string;
  banner: string;
  metadata?: string;
  alternate_link?: string;
  related?: RelatedPage[];
}
