import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_ABOUTPAGES } from '../shared/constants';
import { AboutPage } from '../shared/models/about-page.model';
import { Category } from '../shared/models/category.model';
import { Pagination, RawPagination } from '../shared/models/pagination.model';
import { Fase } from '../shared/models/fase.model';
import { toFormData } from '../shared/helpers/form-data.helper';

@Injectable({
  providedIn: 'root',
})
export class AboutPageService {
  aboutPages: AboutPage[];

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<AboutPage>
  ): Observable<RawPagination<AboutPage>> {
    return this.http.get<RawPagination<AboutPage>>(URL_ABOUTPAGES, {
      params: { ...pagination?.params, ...options },
    });
  }

  reorder(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_ABOUTPAGES}/order/${id}`, {
      previous: data,
    });
  }

  getFase(id: number): Observable<Fase> {
    return this.http
      .get<{ data: Fase }>(`${URL_ABOUTPAGES}/fase/${id}`)
      .pipe(map(({ data }) => data));
  }

  getFases() {
    return this.http.get<{ data: Fase[] }>(`${URL_ABOUTPAGES}/fase`);
  }

  menu() {
    return this.http.get<{ data: AboutPage[] }>(`${URL_ABOUTPAGES}/menu`);
  }

  get(id: number | string): Observable<AboutPage> {
    return this.http
      .get<{ data: AboutPage }>(`${URL_ABOUTPAGES}/${id}`)
      .pipe(map(({ data }) => new AboutPage(data)));
  }

  create(data: AboutPage): Observable<AboutPage> {
    return this.http.post<AboutPage>(`${URL_ABOUTPAGES}`, toFormData(data));
  }

  update(id: number, data: AboutPage): Observable<AboutPage> {
    return this.http.post<AboutPage>(
      `${URL_ABOUTPAGES}/${id}`,
      toFormData(data, 'PUT')
    );
  }

  updateFase(fase: number, data: Fase): Observable<Fase> {
    return this.http.put<Fase>(`${URL_ABOUTPAGES}/fase/${fase}`, data);
  }

  delete(id: number): Observable<Category> {
    return this.http.delete<Category>(`${URL_ABOUTPAGES}/${id}`);
  }
}
