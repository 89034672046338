import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

const styles = {
  small: ['px-3', 'py-1'],
  normal: ['px-6', 'py-2'],
  medium: ['px-8', 'py-3'],
  large: ['px-12', 'py-4'],
  blue: ['text-white', 'bg-sea-blue-700', 'hover:bg-sea-blue-600'],
  danger: ['text-white', 'bg-red-600', 'hover:bg-red-700'],
  white: ['text-blue-900', 'bg-white'],
  gray: [
    'text-blue-700',
    'hover:text-sea-blue-600',
    'bg-dark-gray-200',
    'hover:bg-dark-gray-300',
  ],
  teva: ['text-white', 'bg-teva-teal', 'hover:bg-teva-teal'],
  disabled: ['bg-gray-400', 'text-white'],
};

@Directive({
  selector: '[appButton]',
})
export class ButtonDirective implements OnInit, OnChanges {
  @Input() size: 'small' | 'normal' | 'medium' | 'large' = 'normal';
  @Input() color: 'blue' | 'white' | 'gray' | 'teva' | 'disabled' = 'blue';
  @Input() loading = false;

  private currentClasses = [];
  private loader: SVGElement;

  constructor(private el: ElementRef) {
    this.loader = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.loader.setAttribute('height', '16px');
    this.loader.setAttribute('width', '16px');
    this.loader.setAttribute('class', 'mr-2 fill-current spin');

    const icon = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    icon.setAttribute('href', '#fas-spinner-third');

    this.loader.appendChild(icon);
  }

  ngOnInit(): void {
    this.setLoading();
    this.setClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.setLoading();
    }
    if (changes.size || changes.color) {
      this.setClasses();
    }
  }

  private setLoading() {
    const element = this.el.nativeElement as HTMLElement;

    if (this.loading) {
      element.prepend(this.loader);
    } else if (element.contains(this.loader)) {
      element.removeChild(this.loader);
    }
  }

  private setClasses() {
    const element = this.el.nativeElement as HTMLElement;

    if (this.currentClasses.length) {
      element.classList.remove(...this.currentClasses);
    }

    this.currentClasses = this.getClasses();
    element.classList.add(...this.currentClasses);
  }

  private getClasses(): string[] {
    const classes = [
      'font-bold',
      'rounded',
      'text-center',
      'flex',
      'justify-center',
      'items-center',
      ...styles[this.size],
      ...styles[this.color],
    ];
    return classes;
  }
}
