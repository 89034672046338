import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_AJOVYPAGES } from '../shared/constants';
import toFormData from '../shared/helpers/form-data.helper';
import { Pagination, RawPagination } from '../shared/models/pagination.model';
import { AjovyPage } from '../shared/models/ajovy-page.model';

@Injectable({
  providedIn: 'root',
})
export class AjovyPageService {
  ajovyPages: AjovyPage[];

  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<AjovyPage>
  ): Observable<RawPagination<AjovyPage>> {
    return this.http.get<RawPagination<AjovyPage>>(URL_AJOVYPAGES, {
      params: { ...pagination?.params, ...options },
    });
  }

  reorder(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_AJOVYPAGES}/order/${id}`, {
      previous: data,
    });
  }

  get(id: number): Observable<AjovyPage> {
    return this.http
      .get<{ data: AjovyPage }>(`${URL_AJOVYPAGES}/${id}`)
      .pipe(map(({ data }) => data));
  }

  create(data: AjovyPage): Observable<AjovyPage> {
    return this.http.post<AjovyPage>(`${URL_AJOVYPAGES}`, toFormData(data));
  }

  update(id: number, data: AjovyPage): Observable<any> {
    return this.http.post(`${URL_AJOVYPAGES}/${id}`, toFormData(data, 'PUT'));
  }

  delete(id): Observable<any> {
    return this.http.delete(`${URL_AJOVYPAGES}/${id}`);
  }
}
