export const environment = {
  apiUrl: 'https://care4migraine.nl',
  production: true,
  staging: false,
  gtm: 'GTM-NQRZJ59',
  recaptchaSiteKey: '6LesAxMaAAAAABQ6WYR_uOT6nm-uqJopSHG59dkl',
  firebase: {
    apiKey: 'AIzaSyCHQ-XZzGAMsSbvrygOVBj94bf2H-p0hmg',
    authDomain: 'care4migraine-d8975.firebaseapp.com',
    databaseURL:
      'https://care4migraine-d8975-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'care4migraine-d8975',
    storageBucket: 'care4migraine-d8975.appspot.com',
    messagingSenderId: '526380962769',
    appId: '1:526380962769:web:4a26c5676691e6d16ec6f0',
    measurementId: 'G-BRQ88VZ6W2',
  },
};
