import { BrowserModule, Title } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { RegistrationNumberInterceptor } from './interceptors/registration-number.interceptor';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AuthService } from './services/auth.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { GTM } from './shared/constants';
import { BreadcrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component';

import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl, 'nl');

import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';

import { StatementComponent } from './shared/components/statement/statement.component';
import { InlineSvgIconsComponent } from './shared/components/inline-svg-icons/inline-svg-icons.component';
import { AlertComponent } from './shared/components/admin/alert/alert.component';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { ModalModule } from './modules/modal/modal.module';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    DashboardComponent,
    SidebarComponent,
    SearchFilterPipe,
    FooterComponent,
    BreadcrumbsComponent,
    StatementComponent,
    InlineSvgIconsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'care4migraine-ssr' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    RecaptchaFormsModule,
    RecaptchaModule,
    NgxJsonLdModule,
    ModalModule,
  ],
  exports: [CommonModule],
  providers: [
    AuthService,
    Title,
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: 'googleTagManagerId', useValue: GTM },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RegistrationNumberInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
