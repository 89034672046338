import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
  transform(
    value: string,
    length: number = 256,
    overflow: string = '…'
  ): string {
    let shortened = value.substr(0, length);
    if (value.length > length) { shortened += overflow; }
    return shortened;
  }
}
