/**
 * Transform an object to a {FormData} object.
 *
 * @param data Data to transform
 * @param method Method to state what type of request it is.
 */
export const toFormData = (
  data: Object,
  method?: 'POST' | 'PUT' | 'DELETE'
) => {
  // Transform to formdata
  const formData = new FormData();

  // PHP doesn't populate formdata if the request isn't post. To fix this, we set the method in the form data object.
  if (method) { formData.append('_method', method); }

  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return formData;
};

export default toFormData;
