import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from 'src/app/shared/models/alert.model';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
  alerts: Alert[] = [];
  alertSubscription: Subscription;
  constructor(private router: Router, private alertService: AlertService) {}

  ngOnInit() {
    // subscribe to new alert notifications
    this.alertSubscription = this.alertService
      .onAlert('default-alert')
      .subscribe((alert) => {
        // clear alerts when an empty alert is received
        if (!alert.message) {
          return;
        }

        // add alert to array
        this.alerts.push(alert);

        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 6000);
        }
      });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) {
      return;
    }
    // remove alert
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    const classes = [];

    const alertTypeClass = {
      [AlertType.Success]: 'bg-teva-teal text-white',
      [AlertType.Error]: 'bg-red-100 border-red-400 text-red-700',
      [AlertType.Info]: 'bg-blue-500 border-blue-400 text-white',
      [AlertType.Warning]: 'bg-orange-100 border-orange-500 text-orange-700',
    };

    classes.push(alertTypeClass[alert.type]);

    return classes.join(' ');
  }
}
