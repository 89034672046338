export default class FormHelper {
  /**
   *
   * @param field
   * @param type
   *
   * Default has error check, expects a FormControl and extra type to check for.
   * Returns Boolean
   */
  static hasErrors(field, type: string = null): boolean {
    if (!field.errors) {
      return false;
    }

    return type ? field.errors[type] : true;
  }
}
