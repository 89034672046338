/**
 * Provides a convenient way to centralize the
 * JSON-LD configuration for most content on this website.
 */
export class JSONLDSchema<T> {
  title?: string;
  preview_text?: string;
  text_desktop?: string;
  text?: string;
  created_at: Date;
  updated_at: Date;
  thumbnail?: string;
  banner?: string;
  metadata?: string;
  author?: string;

  constructor(partial?: Partial<T>) {
    Object.assign(this, partial);
  }

  toJSONLD(): { '@context': string; '@type': string } & unknown {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: this.title,
      image: [this.thumbnail, this.banner],
      datePublished: this.created_at,
      dateModified: this.updated_at,
    };

    if (this.author) {
      json['author'] = [{ '@type': 'Person', name: this.author }];
    }

    if (this.metadata) {
      json['keywords'] = this.metadata;
    }

    return json;
  }
}
