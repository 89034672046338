import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import {
  URL_BLOGPOSTS,
  URL_BLOGPOSTS_CATEGORIES,
  URL_BLOGPOSTS_TAGS,
} from '../shared/constants';
import toFormData from '../shared/helpers/form-data.helper';
import { BlogPost } from '../shared/models/blog-post.model';
import { Category } from '../shared/models/category.model';
import { Pagination, RawPagination } from '../shared/models/pagination.model';
import { Tag } from '../shared/models/tag.model';

@Injectable({
  providedIn: 'root',
})
export class BlogPostService {
  constructor(private http: HttpClient) {}

  getAll(
    options: { [key: string]: any } = {},
    pagination?: Pagination<BlogPost>
  ): Observable<RawPagination<BlogPost>> {
    return this.http.get<RawPagination<BlogPost>>(URL_BLOGPOSTS, {
      params: { ...pagination?.params, ...options },
    });
  }

  reorder(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_BLOGPOSTS}/order/${id}`, {
      previous: data,
    });
  }

  reorderCategory(id: number, data: { [action: string]: number }) {
    return this.http.post<any>(`${URL_BLOGPOSTS}/category/order/${id}`, {
      previous: data,
    });
  }

  getTags(
    options: { [key: string]: any } = {},
    pagination?: Pagination<Tag>
  ): Observable<RawPagination<Tag>> {
    return this.http.get<RawPagination<Tag>>(URL_BLOGPOSTS_TAGS, {
      params: { ...pagination?.params, ...options },
    }).pipe(
      map(items => ({
        ...items,
        data: items.data.map((tag) => ({
          ...tag,
          defaultName: tag.name.nl,
          defaultSlug: tag.slug.nl
        }))
      }))
    );
  }

  getForTag(
    id: number,
    pagination?: Pagination<BlogPost>
  ): Observable<BlogPost[]> {
    return this.http.get<any>(`${URL_BLOGPOSTS}/tag-posts/${id}`, {
      params: pagination?.params,
    });
  }

  get(id: number | String): Observable<BlogPost> {
    return this.http
      .get<{ data: BlogPost }>(`${URL_BLOGPOSTS}/${id}`)
      .pipe(map(({ data }) => new BlogPost(data)));
  }

  getCategory(id: number | string): Observable<Category> {
    return this.http
      .get<{ data: Category }>(`${URL_BLOGPOSTS}/category/${id}`)
      .pipe(map(({ data }) => data));
  }

  getTag(id: number | string): Observable<Tag> {
    return this.http
      .get<{ data: Tag }>(`${URL_BLOGPOSTS}/tag/${id}`)
      .pipe(map(({ data }) => data));
  }

  create(data: BlogPost): Observable<BlogPost> {
    return this.http.post<BlogPost>(`${URL_BLOGPOSTS}`, toFormData(data));
  }

  createTag(data): Observable<any> {
    return this.http.post(`${URL_BLOGPOSTS}/tag`, data);
  }

  update(id: number, data: BlogPost): Observable<any> {
    return this.http.post(`${URL_BLOGPOSTS}/${id}`, toFormData(data, 'PUT'));
  }

  updateTag(id, data): Observable<any> {
    return this.http.put(`${URL_BLOGPOSTS}/tag/${id}`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${URL_BLOGPOSTS}/${id}`);
  }

  deleteTag(id): Observable<any> {
    return this.http.delete(`${URL_BLOGPOSTS}/tag/${id}`);
  }
}
