import { Injectable, Inject, Optional } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  cookies: any = {};
  document: any = { cookie: '' };

  get isBrowser() {
    return this.req === null;
  }

  constructor(
    @Optional() @Inject(REQUEST) private req: Request<any>,
    @Optional() @Inject(RESPONSE) private res: Response<any>
  ) {
    if (this.isBrowser) {
      this.document = document;
    } else {
      this.cookies = this.req.cookies;
    }
  }

  getItem(name: string): string | null {
    const cookies: { [key: string]: string | null } = this.getPairs();
    if (name && typeof cookies[name] !== 'undefined') {
      return cookies[name];
    }
    return null;
  }

  setItem(
    name: string,
    value: string,
    expiry?: Date | string,
    path: string = '/'
  ): boolean {
    if (!name) return false;

    if (this.isBrowser) {
      this.setBrowserCookie(name, value, expiry, path);
    } else {
      this.setServerCookie(name, value, expiry, path);
    }

    return true;
  }

  removeItem(name: string, path: string = '/'): boolean {
    if (!this.isBrowser || !name) return false;

    if (this.isBrowser) {
      this.document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
    } else {
      this.cookies[name] = null;
      const expiry = new Date('Thu, 01 Jan 1970 00:00:00 UTC');
      this.res.cookie(name, null, { expires: expiry, path, encode: String });
    }

    return true;
  }

  private getPairs(): { [key: string]: string | null } {
    if (this.isBrowser) {
      const parsed = this.document.cookie.split('; ');
      const cookies: { [key: string]: string | null } = {};
      parsed.forEach((element: string) => {
        if (element) {
          const pair = element.split('=');
          cookies[pair[0]] = typeof pair[1] !== 'undefined' ? pair[1] : null;
        }
      });
      return cookies;
    } else {
      return this.cookies;
    }
  }

  private setBrowserCookie(
    name: string,
    value: string,
    expiry?: Date | string,
    path?: string
  ) {
    let expiryStr = '';
    if (expiry) {
      if (!(expiry instanceof Date)) {
        expiry = new Date(expiry);
      }
      expiryStr = '; expires=' + expiry.toUTCString();
    }
    this.document.cookie = `${name}=${value}${expiryStr}; path=${path}`;
  }

  private setServerCookie(
    name: string,
    value: string,
    expiry?: Date | string,
    path?: string
  ) {
    if (expiry) {
      if (!(expiry instanceof Date)) {
        expiry = new Date(expiry);
      }
      if (expiry.getTime() <= Date.now()) {
        this.removeItem(name, path);
      } else {
        this.cookies[name] = value;
        this.res.cookie(name, value, {
          expires: expiry,
          path,
          encode: String,
        });
      }
    } else {
      this.cookies[name] = value;
      this.res.cookie(name, value, { path, encode: String });
    }
  }
}
