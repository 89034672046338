import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TitleService } from './title.service';
import { SafeUrlService } from './safe-url.service';

export type Breadcrumb = {
  text: string;
  url: string;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private _breadcrumbs: Breadcrumb[] = [];
  public breadcrumbs: ReplaySubject<Breadcrumb[]> = new ReplaySubject();
  public structuredData!: Observable<any>;

  /** Custom values for specific parameters */
  public set customCrumbs(crumbs: { [param: string]: string; }) {
    this._breadcrumbs = this._breadcrumbs.map((crumb) => ({
      ...crumb,
      text: crumbs[crumb.text.substr(1)] ?? crumb.text,
    }));

    this.breadcrumbs.next(this._breadcrumbs);
  }

  constructor(
    private router: Router,
    private safeUrlService: SafeUrlService,
    activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(filter((nav) => nav instanceof NavigationEnd))
      .subscribe(() => {
        this.setBreadcrumbs(activatedRoute.snapshot.root);
      });

    this.structuredData = this.getStructuredData();
  }

  fixTitle(title: string): void {
    this._breadcrumbs.map((breadcrumb: Breadcrumb) => {
      breadcrumb.text =
        breadcrumb.text.replace(/\W/g, '').toLowerCase() ===
          title.replace(/\W/g, '').toLowerCase()
          ? title
          : breadcrumb.text;
      return breadcrumb;
    });
    this.breadcrumbs.next(this._breadcrumbs);
  }

  setBreadcrumbs(route: ActivatedRouteSnapshot) {
    this._breadcrumbs = [];

    while (route) {
      const crumbs = route.data?.crumbs;
      if (crumbs) {
        this._breadcrumbs.push(...this.mapCrumbs(crumbs, route));
      }

      route = route.firstChild ?? null;
    }

    this.breadcrumbs.next(this._breadcrumbs);
  }

  private mapCrumbs(
    crumbs: string[],
    route: ActivatedRouteSnapshot
  ): Breadcrumb[] {
    return crumbs.map((crumb: string) => {
      const param = crumb.substr(1);

      if (route.paramMap.has(param)) {
        crumb = route.paramMap.get(param).replace(/-/gi, ' ');
      }

      return {
        text: crumb,
        url: this.constructUrl(route),
      };
    });
  }

  private constructUrl({ url, parent }: ActivatedRouteSnapshot): string {
    while (parent) {
      url = [...parent.url, ...url];
      parent = parent.parent;
    }

    return url.join('/');
  }

  private getStructuredData(): Observable<any> {
    return this.breadcrumbs.pipe(
      map((crumbs) => ({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: crumbs.map((crumb, index) => {
          const item = {
            '@type': 'ListItem',
            position: index + 1,
            name: crumb.text,
          };

          if (index < crumbs.length - 1) {
            item['item'] = this.safeUrlService.getFullUrl(crumb.url);
          }

          return item;
        }),
      }))
    );
  }
}
