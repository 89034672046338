import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SafeUrlService {
  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    @Optional() @Inject(REQUEST) private request: Request
  ) { }

  public getFullUrl(path: string): string {
    path = path.startsWith('/') ? path.substring(1) : path;

    if (isPlatformBrowser(this.platform)) {
      return `${window.location.protocol}//${window.location.hostname}/${path}`;
    } else {
      return `${this.getServerProtocol()}://${this.getServerHost()}/${path}`;
    }
  }

  /**
   * Get a safe server hostname. Either a subdomain of everyhwereim.com or care4migraine.nl itself.
   * @returns A safe server hostname
   */
  public getServerHost(): string {
    const host: string = this.request.headers['x-forwarded-host'];

    // We need either a subdomain of everywhereim.com or care4migraine.nl itself.
    if (host?.endsWith('.everywhereim.com')) {
      return host;
    }

    // In case of development, we don't really care what the host is.
    if (!environment.production && !environment.staging) {
      return host;
    }

    return 'care4migraine.nl';
  }

  /**
   * Return the forwarded protocol. If no valid protocol is found, it defaults to https.
   * @returns The forwarded protocol (defaults to https)
   */
  public getServerProtocol(): string {
    const protocol: string = this.request.headers['x-forwarded-proto'];

    if (protocol?.match(/^(https?)$/gi)) {
      return protocol;
    }

    return 'https';
  }
}